import React, { useEffect, useRef } from "react";
import i18n from "../../i18n";

import { useTranslation } from "react-i18next";
import { getCHPYPapamByName, getLang, setCookie } from "../../utils/utils";
import IconArrow from "../../images/jsIcons/IconArrow";
import { useLocalStorage } from "usehooks-ts";
import { API_INFO } from "../../api/info";
import FlagImg from "./FlagImg";
import { setNewSiteInfo } from "../../store/siteInfoSlice";
import { useDispatch, useSelector } from "react-redux";

export default function ChoseLang({ classNameUl }) {
  const chooseLangRef = useRef(null);
  const languages = useSelector((state) => state.common.languages);

  const chosenLang = getLang();
  const [selectLang, setSelectLang] = useLocalStorage("lang", chosenLang);
  const getSelect = (lang) => (lang === chosenLang ? "select" : "");
  const isLangParam = useRef(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!languages.length) return;
    console.log("selectLang", selectLang);

    const paramLang = getCHPYPapamByName("lang");
    if (paramLang && languages.length && !isLangParam.current) {
      const currentLang = languages.find((el) => `${el.code}_${el.country}` === paramLang);

      if (!currentLang) return;
      const code = currentLang.code;
      changeLang(code);
      isLangParam.current = true;
      return;
    }

    changeLang(selectLang);
  }, [selectLang, languages]);

  const cookieLang = () => {
    const cookieLang = {};

    languages?.forEach((el) => {
      cookieLang[el.code] = `${el.code}_${el.country}`;
    });

    return cookieLang;
  };

  const changeLang = (lang) => {
    setCookie("language", cookieLang()?.[lang]);

    API_INFO.listSiteCategory()
      .then((e) => {
        dispatch(setNewSiteInfo(e.data.categories));
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setSelectLang(lang);
        i18n.changeLanguage(lang);
      });
  };

  return (
    <button
      className="chose-lang"
      onMouseOver={() => chooseLangRef.current.classList.remove("close")}
    >
      <span className="lang-item main select">
        <FlagImg flagCode={languages.find((el) => el.code == selectLang)?.flag} />

        <span className="text">
          {languages.find((el) => el.code == selectLang)?.title || ""}
          <IconArrow className="chose-lang__arrow" />
        </span>
      </span>
      <ul ref={chooseLangRef} className={classNameUl}>
        {languages.map((el, indx) => (
          <li
            key={indx}
            onClick={() => {
              setSelectLang(el.code);
              chooseLangRef.current.classList.add("close");
            }}
            className={`lang-item ${getSelect(el)}`}
          >
            <button>
              <FlagImg flagCode={el.flag} />
              <span>{t(el.title)}</span>
            </button>
          </li>
        ))}
      </ul>
      <div className="chose-lang__plug" />
    </button>
  );
}
